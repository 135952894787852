import { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import InputField, { INPUT_TYPE_SELECT } from 'components/InputField';
import Button from 'components/Button';
import { isValidEmail } from 'utils/form';
import { useNavigate } from 'react-router-dom';
import { getLocaleMap } from 'utils/locales';
import Select from 'components/Select';

const EditUserForm = ({ user, roles, instances, onSave, ...props }) => {
  const [lm, setLm] = useState({});
  const [isAdmin, setIsAdmin] = useState(user?.role === 0);

  useEffect(() => {
    getLocaleMap([
      'labels_display_name',
      'labels_email',
      'labels_password',
      'labels_confirm_password',
      'labels_role',
      'errors_required',
      'errors_invalid_email',
      'errors_password_not_match',
      'set_password_button',
      'save_button'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const getFormValidation = (_user) => (values) => {
    const { display_name, email, role, password, confirm_password } = values;
    const errors = {};
    if (!display_name) {
      errors.display_name = lm.errors_required;
    }
    if (!email) {
      errors.email = lm.errors_required;
    } else if (!isValidEmail(email)) {
      errors.email = lm.errors_invalid_email;
    }
    if (!role) {
      if (role !== 0) {
        errors.role = lm.errors_required;
      }
    }
    if (!_user) {
      if (!password) {
        errors.password = lm.errors_required;
      }
      if (!confirm_password) {
        errors.confirm_password = lm.errors_required;
      }
      if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = lm.errors_password_not_match;
        errors.password = lm.errors_password_not_match;
      }
    }
    return errors;
  };

  const navigate = useNavigate();
  const initialValues = useMemo(() => ({
    display_name: user?.display_name || '',
    email: user?.email || '',
    // eslint-disable-next-line no-nested-ternary
    role: user?.role ? user?.role : (user?.role === 0 ? user?.role : 2),
    ...(user ? {} : {
      password: '',
      confirm_password: '',
    }),
    user_instances: user?.user_instances || [],
    initial_instances: user?.user_instances.map((user_instance) => user_instance?.instance?.instance_id) || [],
    selected_instances: user?.user_instances.map((user_instance) => user_instance?.instance?.instance_id) || [],
  }), [user]);

  const roleOptions = useMemo(() => roles.map((role) => ({
    label: role.description,
    value: role.role_id,
  })), [roles]);

  const instanceOptions = useMemo(() => instances.map((instance) => ({
    label: instance.name,
    value: instance.instance_id,
  })), [instances]);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      validate={getFormValidation(user)}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, touched, isSubmitting, dirty }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                <InputField
                  name="display_name"
                  label={lm.labels_display_name}
                  value={values.display_name}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={touched.display_name && !!errors.display_name}
                />
                <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                  {(touched.display_name && !!errors.display_name) ? errors.display_name : '\xa0'}
                </MKTypography>
              </Grid>
              <Grid container item xs={12}>
                <InputField
                  name="email"
                  label={lm.labels_email}
                  value={values.email}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  disabled={!!user}
                  error={touched.email && !!errors.email}
                />
                <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                  {(touched.email && !!errors.email) ? errors.email : '\xa0'}
                </MKTypography>
              </Grid>
              {!user && (
                <Grid container item xs={12}>
                  <InputField
                    name="password"
                    label={lm.labels_password}
                    value={values.password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={touched.password && !!errors.password}
                  />
                  <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                    {(touched.password && !!errors.password) ? errors.password : '\xa0'}
                  </MKTypography>
                </Grid>
              )}
              {!user && (
                <Grid container item xs={12}>
                  <InputField
                    name="confirm_password"
                    label={lm.labels_confirm_password}
                    value={values.confirm_password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={touched.confirm_password && !!errors.confirm_password}
                  />
                  <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                    {(touched.confirm_password && !!errors.confirm_password) ? errors.confirm_password : '\xa0'}
                  </MKTypography>
                </Grid>
              )}
              {user && (
                <Grid container item xs={12} marginBottom="1rem" alignItems="center">
                  <MKTypography style={{ fontSize: '1rem', marginRight: '2rem' }}>{lm.labels_password}</MKTypography>
                  <Button
                    variant="gradient"
                    color="info"
                    onClick={() => navigate(`set_password?username=${user.email}`)}
                  >
                    {lm.set_password_button}
                  </Button>
                </Grid>
              )}
              <Grid container item xs={12}>
                <InputField
                  name="role"
                  label={lm.labels_role}
                  value={values.role}
                  type={INPUT_TYPE_SELECT}
                  // setFieldValue={setFieldValue}
                  onChange={(v) => {
                    setFieldValue('role', v);
                    if (v === 0) {
                      setFieldValue('selected_instances', []);
                      setIsAdmin(true);
                    } else {
                      setIsAdmin(false);
                    }
                  }}
                  options={roleOptions}
                  invalid={touched.role && !!errors.role}
                />
                <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                  {(touched.role && !!errors.role) ? errors.role : '\xa0'}
                </MKTypography>
              </Grid>

              {!isAdmin && (
                <Grid container item flexDirection="column" xs={12}>
                  <Select
                    multiple
                    label="Instance"
                    value={values.selected_instances}
                    onChange={(v) => setFieldValue('selected_instances', v)}
                    options={instanceOptions}
                  />
                </Grid>
              )}

            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : lm.save_button}
                </Button>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditUserForm.propTypes = {
  user: PropTypes.shape({
    display_name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.number,
    user_instances: PropTypes.array,
  }),
  onSave: PropTypes.func,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      role_id: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  instances: PropTypes.array,
};

EditUserForm.defaultProps = {
  user: {},
  onSave: () => {},
  roles: [],
  instances: [],
};

export default EditUserForm;
