import api from 'api';

export const getInstances = (params) => {
  return api.get('/instances', params, 'bearer');
};

export const createUserInstance = (body) => {
  return api.post('/user_instances', body, 'bearer');
};

export const deleteUserInstance = (id) => {
  return api.delete(`/user_instances/${id}`, 'bearer');
};
