import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import MKBox from 'components/MaterialKit/MKBox';
import { useAuth } from 'contexts/auth';
import { handleErrorResponse } from 'utils/general';
import { getInstances } from 'api/instances';
import DataTable from 'components/DataTable';
import { useNavigate } from 'react-router-dom';
import { instancesTableColumns } from './tablecolumns';

const InstancesTableSection = ({
  background_color,
  page_options,
  addable,
  editable,
  deletable,
  add_button_label,
  edit_button_label,
  delete_button_label,
  action_button_props,
  header_background_color,
  row_background_color,
  ...props
}) => {
  const [totalRows, setTotalRows] = useState(0);
  const [instances, setInstances] = useState([]);
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const isAdmin = auth.user?.role.role_id === 0;
  const instanceIds = useMemo(() => {
    get(auth, ['user', 'user_instances'], []).map((userInstance) => userInstance.instance.instance_id);
  }, [auth]);

  const fetchInstancesFromApi = useCallback(() => {
    if (instanceIds || isAdmin) {
      return getInstances({
        $orderBy: 'createddate',
        ...(instanceIds && { 'instance_id[in]': instanceIds }),
      })
        .then(({ data, headers }) => {
          setInstances(data || []);
          const contentRange = get(headers, 'content-range');
          const size = Number(contentRange.split('/')[1]);
          setTotalRows(size);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [instanceIds, isAdmin, setAuth]);

  const onClickAdd = useCallback(() => {
    navigate('/create_instance');
  }, [navigate]);

  const onClickEdit = useCallback((instanceId) => {
    navigate(`/instance?instance_id=${instanceId}`);
  }, [navigate]);

  const onLoginInstance = useCallback((id) => {
    localStorage.setItem('asInstanceId', id);
    // navigate('/finger-print');
    window.location.replace('/ams/finger-print');
  }, []);

  useEffect(() => {
    localStorage.removeItem('asInstanceId');
    fetchInstancesFromApi();
  }, [fetchInstancesFromApi]);

  return (
    <MKBox
      px={{ xs: 0, md: 1 }}
      py={{ xs: 1, md: 2 }}
      bgColor={background_color}
      {...props}
    >
      {/* <FetchingTable
        table_name="instances"
        id_field="instance_id"
        extra_params={{
          '$orderBy': 'createddate',
          // ...(instanceIds && { 'instance_id[in]': instanceIds }),
          'instance_id[in]': instanceIds,
        }}
        page_options={page_options}
        columns={instancesTableColumns}
        add_data_path="/instances/add"
        edit_data_path="/instances/edit"
        addable={addable}
        editable={editable}
        deletable={deletable}
        add_button_label={add_button_label}
        edit_button_label={edit_button_label}
        delete_button_label={delete_button_label}
        action_button_props={action_button_props || null}
        headerBackgroundColor={header_background_color}
        rowBackgroundColor={row_background_color}
        /> */}
      <DataTable
        selectable
        data={instances}
        columns={instancesTableColumns}
        idField="instance_id"
        onClickRow={editable && isAdmin ? onClickEdit : null}
        onPressAdd={addable && isAdmin ? onClickAdd : null}
        onPressEdit={editable && isAdmin ? onClickEdit : null}
        rowsPerPageOptions={page_options}
        totalCount={totalRows}
        onLoginInstance={onLoginInstance}
      />
    </MKBox>
  );
};

InstancesTableSection.propTypes = {
  background_color: PropTypes.string,
  page_options: PropTypes.arrayOf(
    PropTypes.object,
  ),
  addable: PropTypes.bool,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  add_button_label: PropTypes.string,
  edit_button_label: PropTypes.string,
  delete_button_label: PropTypes.string,
  action_button_props: PropTypes.object,
  header_background_color: PropTypes.object,
  row_background_color: PropTypes.object,
};

export default InstancesTableSection;
