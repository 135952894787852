import { datetimeFormatter } from 'utils/datetime';

export const instancesTableColumns = [
  { field: 'name', label: 'Name' },
  { field: 'endpoint', label: 'Endpoint' },
  { field: 'database_username', label: 'Database Username' },
  { field: 'database_password', label: 'Database Password' },
  { field: 'database_url', label: 'Database Url' },
  { field: 'lastmoddate', label: 'Updated Date', formatter: datetimeFormatter },
];
