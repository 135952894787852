import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Checkbox from '@mui/material/Checkbox';
import TablePagination from '@mui/material/TablePagination';
import MKTable from 'components/MaterialKit/MKTable';
import MKBox from 'components/MaterialKit/MKBox';
import AnchorMenu from 'components/AnchorMenu';
import Button from 'components/Button';
import { withProps } from 'utils/hoc';
import { getLocaleMap } from 'utils/locales';
import TablePageActionButtonGroup from './TablePageActionButtonGroup';

const DataTable = ({ columns, data, idField, selectable, objectTypes, typeRouting, onSelectRow, onClickRow, onPressAdd, onPressEdit, onPressDelete, onPressCopy, onPressPaste, onLoginInstance, totalCount, currentPage, onPageChange, rowsPerPage, onRowsPerPageChange, rowsPerPageOptions, paginationDisabled, sortBy, sortDirection, onSort, pageButtonProps, addButtonLabel, editButtonLabel, deleteButtonLabel, copyButtonLabel, pasteButtonLabel, loginInstanceButtonLabel, headerBackgroundColor, rowBackgroundColor, actionButtonProps, userNoNavigateRight, ...props }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [localeMapping, setLocaleMapping] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getLocaleMap(['add_button', 'edit_button', 'delete_button', 'confirmation_prompts_delete', 'copy_button', 'paste_button',
      'labels_to', 'labels_of', 'labels_more_than', 'labels_page_size', 'user_has_no_right_to_access'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const onOpenMenu = useCallback(({ currentTarget }) => setMenuAnchor(currentTarget), []);

  const selectedItem = useMemo(() => (data || []).find(
    (datum) => datum[idField] === selectedId,
  ), [data, idField, selectedId]);

  const headers = useMemo(() => [
    ...(selectable ? [{
      name: 'selected',
      label: '',
      width: '0',
      headerBackgroundColor,
    }] : []),
    ...columns.map((column, i) => {
      const { label, field, ...colProps } = column;
      let arrowIcon;

      if (sortDirection === 'asc') {
        arrowIcon = 'arrow_drop_up';
      } else if (sortDirection === 'desc') {
        arrowIcon = 'arrow_drop_down';
      }
      return {
        name: field,
        label: (
          <Grid container justifyContent={i > 0 ? 'center' : 'flex-start'} alignItems="center" flexWrap="nowrap">
            {label !== undefined ? label : startCase(field)}
            {field === sortBy && (
              <Icon fontSize="default" color="dark">
                {arrowIcon}
              </Icon>
            )}
          </Grid>
        ),
        align: i > 0 ? 'center' : 'left',
        headerBackgroundColor,
        ...colProps,
      };
    }),
  ], [columns, headerBackgroundColor, selectable, sortBy, sortDirection]);

  const rows = useMemo(() => (data || []).map((datum) => {
    return columns.reduce((rowData, column) => {
      const { field, to, urlParams, href, formatter, content, renderContent, onClick } = column;
      const updatedRowData = { ...rowData };
      const displayText = formatter ? formatter(get(datum, field)) : get(datum, field);

      const parsedTo = (urlParams || []).reduce((result, urlParam) => {
        return result.replaceAll(`:${urlParam}`, get(datum, urlParam));
      }, to);

      if (typeof renderContent === 'function') {
        updatedRowData[field] = renderContent(datum);
      } else if (content) {
        updatedRowData[field] = content;
      } else if (to || href || onClick) {
        updatedRowData[field] = (
          <Button
            variant="text"
            onClick={() => (typeof onClick === 'function' ? onClick(datum) : null)}
            to={parsedTo}
            href={href}
            fullWidth
          >
            {displayText}
          </Button>
        );
      } else {
        updatedRowData[field] = displayText;
      }
      if (selectable) {
        updatedRowData.selected = (
          <Checkbox
            checked={selectedId === datum[idField]}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (onSelectRow) {
                onSelectRow(datum[idField], e.target.checked);
              }
              setSelectedId(e.target.checked ? datum[idField] : null);
            }}
          />
        );
      }
      return updatedRowData;
    }, {});
  }), [data, columns, selectable, selectedId, idField, onSelectRow]);

  const actionButtons = useMemo(() => [
    ...(typeof onPressAdd === 'function' ? [{
      label: localeMapping.add_button || addButtonLabel || 'Add',
      color: 'success',
      button_type: 'add_button',
      onClick: objectTypes.length > 0 ? onOpenMenu : () => onPressAdd('default'),
    }] : []),
    ...(typeof onPressEdit === 'function' ? [{
      label: localeMapping.edit_button || editButtonLabel || 'Edit',
      color: 'info',
      disabled: !selectedId,
      button_type: 'edit_button',
      to: typeRouting[selectedItem?.type] || typeRouting?.default,
      onClick: () => onPressEdit(selectedId),
    }] : []),
    ...(typeof onPressDelete === 'function' ? [{
      label: localeMapping.delete_button || deleteButtonLabel || 'Delete',
      color: 'error',
      disabled: !selectedId,
      button_type: 'delete_button',
      onClick: () => {
        if (window.confirm(localeMapping.confirmation_prompts_delete || 'Are you sure you want to delete this item?')) {
          return Promise.resolve(onPressDelete(selectedId))
            .then(() => setSelectedId(null));
        }
        return Promise.resolve();
      },
    }] : []),
    ...(typeof onPressCopy === 'function' ? [{
      label: localeMapping.copy_button || copyButtonLabel || 'Copy',
      color: 'dark',
      button_type: 'copy_button',
      disabled: !selectedId,
      onClick: () => onPressCopy(selectedId),
    }] : []),
    ...(typeof onPressPaste === 'function' ? [{
      label: localeMapping.paste_button || pasteButtonLabel || 'Paste',
      color: 'secondary',
      button_type: 'paste_button',
      onClick: onPressPaste,
    }] : []),
    ...(typeof onLoginInstance === 'function' ? [{
      label: localeMapping.login_instance_button || loginInstanceButtonLabel || 'Login Instance',
      color: 'secondary',
      disabled: !selectedId,
      button_type: 'login_instance_button',
      onClick: () => onLoginInstance(selectedId),
    }] : []),
  ], [onPressAdd, localeMapping.add_button, localeMapping.edit_button, localeMapping.delete_button, localeMapping.copy_button, localeMapping.paste_button, localeMapping.login_instance_button, localeMapping.confirmation_prompts_delete, addButtonLabel, objectTypes.length, onOpenMenu, onPressEdit, editButtonLabel, selectedId, typeRouting, selectedItem?.type, onPressDelete, deleteButtonLabel, onPressCopy, copyButtonLabel, onPressPaste, pasteButtonLabel, onLoginInstance, loginInstanceButtonLabel]);

  const objectTypeOptions = useMemo(() => objectTypes.map((type) => ({
    label: type.name,
    value: type.object_type_id,
  })), [objectTypes]);

  return (
    <MKBox>
      {actionButtons.length > 0 && (
        <MKBox display="flex" flexDirection="row" pb={1}>
          {actionButtons.map((actionButton, idx) => {
            const { label, color, onClick, to, disabled, button_type } = actionButton;
            if (Object.keys(actionButtonProps || {}).length !== 0) {
              const customButtonProps = actionButtonProps;
              const sxContainer = {
                '& .Mui-disabled': {
                  color: '#FFF',
                },
              };
              sxContainer.minWidth = '150px';
              sxContainer.minHeight = '50px';
              sxContainer.backgroundSize = '100% 100%';
              sxContainer.backgroundRepeat = 'no-repeat';
              sxContainer.backgroundPosition = 'center';
              if (button_type === 'add_button' || button_type === 'edit_button') {
                sxContainer.backgroundImage = 'url(http://ec2-18-167-72-210.ap-east-1.compute.amazonaws.com/ams-server/contents/files/1712046662671-Action_Button_bg.png)';
              } else {
                sxContainer.backgroundImage = 'url(http://ec2-18-167-72-210.ap-east-1.compute.amazonaws.com/ams-server/contents/files/1712045803400-Delete_Button_bg.png)';
              }
              return (
                <MKBox ml={idx > 0 ? 1 : 0}>
                  <Button
                    onClick={onClick}
                    to={to}
                    circular={false}
                    variant="text"
                    fullWidth
                    iconOnly={false}
                    disabled={disabled}
                    {...customButtonProps}
                    {...(sxContainer ? { sx: sxContainer } : {})}
                  >
                    {label}
                  </Button>
                </MKBox>
              );
            }

            return (
              <MKBox ml={idx > 0 ? 1 : 0}>
                <Button
                  size="small"
                  variant="gradient"
                  color={color}
                  onClick={onClick}
                  to={to}
                  disabled={disabled}
                >
                  {label}
                </Button>
              </MKBox>
            );
          })}
        </MKBox>
      )}
      <MKTable
        columns={headers}
        rows={rows}
        onClickHeader={onSort}
        getRowProps={typeof onClickRow === 'function' ? (row, idx) => ({
          hover: true,
          sx: {
            'cursor': 'pointer',
            '&:nth-of-type(even)': {
              backgroundColor: rowBackgroundColor,
            },
          },
          onClick: () => {
            const clickedItem = data[idx];
            return Promise.resolve(onClickRow(clickedItem[idField]))
              .then(() => {
                const targetRoute = typeRouting[clickedItem?.type] || typeRouting?.default;
                if (targetRoute) {
                  if (!userNoNavigateRight.includes(clickedItem?.type)) {
                    navigate(typeRouting[clickedItem?.type] || typeRouting?.default);
                  } else {
                    alert(localeMapping.user_has_no_right_to_access);
                  }
                }
              });
          },
        }) : null}
      />
      {!paginationDisabled && (
        <TablePagination
          component="div"
          showFirstButton
          showLastButton
          count={totalCount}
          page={currentPage}
          onPageChange={(e, page) => onPageChange(page)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(e) => onRowsPerPageChange(e.target.value)}
          rowsPerPageOptions={rowsPerPageOptions}
          labelDisplayedRows={({ from, to, count, page }) => `${from} ${localeMapping.labels_to} ${to} ${localeMapping.labels_of} ${count !== -1 ? count : `${localeMapping.labels_more_than} ${to}`}`}
          labelRowsPerPage={localeMapping.labels_page_size}
          ActionsComponent={withProps(TablePageActionButtonGroup, { buttonProps: pageButtonProps })}
          sx={{
            '.MuiTablePagination-spacer': {
              display: 'none',
            },
          }}
        />
      )}
      <AnchorMenu
        items={objectTypeOptions}
        onClickItem={onPressAdd}
        anchor={menuAnchor}
        setAnchor={setMenuAnchor}
      />
    </MKBox>
  );
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      field: PropTypes.string,
      to: PropTypes.string,
      href: PropTypes.string,
      urlParams: PropTypes.arrayOf(PropTypes.string),
      formatter: PropTypes.func,
      content: PropTypes.node,
      renderContent: PropTypes.func,
      onClick: PropTypes.func,
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.object),
  idField: PropTypes.string,
  selectable: PropTypes.bool,
  objectTypes: PropTypes.object,
  typeRouting: PropTypes.object,
  onSelectRow: PropTypes.func,
  onClickRow: PropTypes.func,
  onPressAdd: PropTypes.func,
  onPressEdit: PropTypes.func,
  onPressDelete: PropTypes.func,
  onPressCopy: PropTypes.func,
  onPressPaste: PropTypes.func,
  onLoginInstance: PropTypes.func,
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  paginationDisabled: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  onSort: PropTypes.func,
  pageButtonProps: PropTypes.object,
  addButtonLabel: PropTypes.string,
  editButtonLabel: PropTypes.string,
  deleteButtonLabel: PropTypes.string,
  copyButtonLabel: PropTypes.string,
  pasteButtonLabel: PropTypes.string,
  loginInstanceButtonLabel: PropTypes.string,
  headerBackgroundColor: PropTypes.string,
  rowBackgroundColor: PropTypes.string,
  actionButtonProps: PropTypes.object,
  userNoNavigateRight: PropTypes.array,
};

DataTable.defaultProps = {
  columns: [],
  data: [],
  idField: 'id',
  selectable: false,
  objectTypes: [],
  typeRouting: {},
  onSelectRow: null,
  onClickRow: null,
  onPressAdd: null,
  onPressEdit: null,
  onPressDelete: null,
  onPressCopy: null,
  onPressPaste: null,
  onLoginInstance: null,
  totalCount: 0,
  currentPage: 0,
  onPageChange: () => {},
  rowsPerPage: 10,
  onRowsPerPageChange: () => {},
  rowsPerPageOptions: [5, 10, 15, 20],
  paginationDisabled: false,
  sortBy: null,
  sortDirection: null,
  onSort: () => {},
  pageButtonProps: {},
  addButtonLabel: 'Add',
  editButtonLabel: 'Edit',
  deleteButtonLabel: 'Delete',
  copyButtonLabel: 'Copy',
  pasteButtonLabel: 'Paste',
  loginInstanceButtonLabel: 'Login Instance',
  headerBackgroundColor: null,
  rowBackgroundColor: null,
  userNoNavigateRight: [],
};

export default DataTable;
