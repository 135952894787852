// Modal.js
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';
import { logout } from 'api/auth';
import { handleErrorResponse } from 'utils/general';
import { initialAuthState, useAuth } from 'contexts/auth';
import { getLocaleMap } from 'utils/locales';

const UserLogoutModal = ({ isOpen, onClose }) => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['logout_button'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const handleLogout = useCallback(() => {
    return logout()
      .then(() => {
        setAuth(initialAuthState);
        localStorage.removeItem('asInstanceId');
        navigate('/login');
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        onClose();
      });
  }, [onClose, navigate, setAuth]);

  if (!isOpen) return null;

  return (
    <MKBox
      sx={{
        position: 'absolute',
        borderRadius: '10px',
        boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#FFFFFF',
        top: '115%',
        display: 'block',
        right: '0',
        zIndex: 'modal',
      }}
    >
      <MKBox
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: '0.3rem',
          px: '1rem',
        }}
      >
        <LogoutIcon onClick={handleLogout} fontSize="medium" />
        <Button
          variant="text"
          onClick={handleLogout}
          sx={{
            p: '0',
          }}
        >
          <span style={{ color: 'dark', marginLeft: '0.5rem', fontSize: 'medium' }}>{lm.logout_button}</span>
        </Button>
      </MKBox>
    </MKBox>
  );
};

UserLogoutModal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default UserLogoutModal;
